import { ActionTree } from 'vuex';
import { ExamState } from './types';
import { RootState } from '../types';

export const actions: ActionTree<ExamState, RootState> = {
    recordTimerStart({ commit }, payload: { uid: string; examId: string; startedAt: string | undefined }): void {
        const startTime = payload.startedAt && payload.startedAt !== '' ? new Date(payload.startedAt).getTime() : Date.now();
        commit('setExamTime', {
            id: payload.examId,
            startTime,
        });
    },
};
