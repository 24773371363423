import client from '@/api/client';
import { Course } from '@/api/userAppApi/types';
import DateUtils from '@/utils/DateUtils';
import store from '@/store';

import { memoize } from 'lodash';

export type Courses = { [key in 'backNumber' | 'waiting' | 'working' | 'available' | 'all']: Course[] };

export const _fetchCourses = memoize(async (): Promise<Courses> => {
    const courses = (
        await client.get<Course[]>('/courses', {
            headers: {
                'x-uid': store.getters['auth/user']?.id,
            },
        })
    ).data;

    const now = new Date();
    return courses.reduce<Courses>(
        (result, course) => {
            if (DateUtils.isNowWithinRange(course.startedAt || null, course.finishedAt || null)) {
                result.available.push(course);
                if (course.progress === -1) {
                    result.waiting.push(course);
                } else if (course.progress !== undefined && course.progress > -1 && !course.completed) {
                    result.working.push(course);
                }
            }
            if (course.completed || (course.finishedAt && new Date(course.finishedAt) < now)) {
                result.backNumber.push(course);
            }
            return result;
        },
        {
            available: [],
            working: [],
            waiting: [],
            backNumber: [],
            all: courses,
        }
    );
});

export const fetchCourses = async (useCache = false): Promise<Courses> => {
    if (!useCache) {
        _fetchCourses.cache.clear?.();
    }
    return _fetchCourses();
};

export const fetchCourse = async (id: string): Promise<Course> => {
    return (
        await client.get(`/courses/${id}`, {
            headers: {
                'x-uid': store.getters['auth/user']?.id,
            },
        })
    ).data;
};
