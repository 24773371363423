
import { namespace } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '@/store/auth/types';
import Network from '@/utils/Network';

const AuthModule = namespace('auth');

@Component
export default class TextHtml extends Vue {
    @Prop({ type: String, default: '' })
    html!: string;

    @Prop({ type: Boolean, default: true })
    hasHorizontalPadding!: boolean;

    @Prop({ type: String, default: 'npc' })
    company!: string;

    @AuthModule.Getter('user')
    user?: User;

    mounted(): void {
        this.$nextTick(async () => {
            await this.downloadImageLinks();

            const postElement = this.$refs.postHtml as HTMLElement;
            postElement.addEventListener('click', async (event) => {
                const target = event.target as HTMLElement;
                if (target.nodeName !== 'A') {
                    return;
                }

                const anchor = target as HTMLAnchorElement;
                if (
                    anchor.href.startsWith(process.env.VUE_APP_API_URL_CONTENTS_PROXY as string) ||
                    anchor.href.startsWith(process.env.VUE_APP_API_URL_CONTENTS_PROXY_V2 as string)
                ) {
                    event.preventDefault();
                    // event.stopPropagation();

                    this.loadContent(anchor.href);
                }
            });
        });
    }

    // コンテンツシャワーの記事ではupdate()は呼ばれないが、教材ではmounted()とupdated()が呼ばれる
    // 逆に、「次の教材」やブラウザバックではmounted()は呼ばれない。
    // また、update()は処理が終わる前に複数回呼ばれることがある。
    // これらに対処するためにlinkProcessedでコントロールしている。trueやfalseを代入している位置も重要
    async updated(): Promise<void> {
        await this.downloadImageLinks();
    }

    //TODO SP・PC判別
    theClass(): string {
        const _class = '';
        /*if (!this.hasHorizontalPadding) {
            _class = '-no-padding-h';
        }*/
        return _class;
    }

    replaceEnvStr(html: string): string {
        return html.replace(/\$\{CONTENTS_HOST\}/g, process.env.VUE_APP_API_URL_CONTENTS_PROXY_V2 as string);
        // return html.replace(/\$\{CONTENTS_HOST\}\/([\w-]+)\/(.+)/g, `${process.env.VUE_APP_API_URL_CONTENTS_PROXY_V2}/$1/${this.company}/$2`);
    }

    async downloadImageLinks(): Promise<void> {
        const images = this.$el.querySelectorAll('img');
        images.forEach(async (image) => {
            const url = image.src;
            image.src = '';
            image.src = (await Network.downloadImageLinks(url)) ?? url;
        });
    }

    async loadContent(href: string): Promise<void> {
        const URL = window.URL || window.webkitURL;

        const res = await Network.fetchData(href);
        if (res == null) {
            return;
        }

        const filename = href.split('/').pop();
        const contentType = res.headers['content-type'];
        const data = URL.createObjectURL(new Blob([res.data], { type: contentType }));

        const a = document.createElement('a');
        a.href = data;
        a.setAttribute('target', '_blank');
        a.setAttribute('rel', 'noopener noreferrer');
        a.setAttribute('donwload', filename ?? 'file');
        a.setAttribute('type', contentType);
        a.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(() => {
            const URL = window.URL || window.webkitURL;
            URL.revokeObjectURL(data);
            a.remove();
        }, 100);
    }
}
