import { UiState } from './types';

export const getDefaultState: () => UiState = () => {
    return {
        openLessons: [],
        scrollOffset: 0,
    };
};

export const state = getDefaultState();
