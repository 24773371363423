import { GetterTree } from 'vuex';
import { ExamState, ExamTimer } from './types';
import { RootState } from '../types';

export const getters: GetterTree<ExamState, RootState> = {
    getExamTimer(state): (examId: string) => ExamTimer | undefined {
        return (examId: string) => {
            if (state.examTimers.length === 0) {
                return undefined;
            } else {
                return state.examTimers.find((timer) => timer.id === examId);
            }
        };
    },
    inProgressExamTopicId(state): string | undefined {
        return state.inProgressExamTopicId;
    },
};
