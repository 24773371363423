export default class MobileDetect {
    public static isMobile(): boolean {
        let _bool = false;
        const userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.indexOf('iphone') != -1) {
            _bool = true;
        } else if (userAgent.indexOf('ipad') != -1) {
            _bool = true;
        } else if (userAgent.indexOf('android') != -1) {
            if (userAgent.indexOf('mobile') != -1) {
                _bool = true;
            } else {
                _bool = true;
            }
        }
        return _bool;
    }
}
