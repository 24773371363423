
import { Component, Vue, Prop } from 'vue-property-decorator';
import Button from '@/components/atoms/Button.vue';
import TextHtml from '@/components/atoms/TextHtml.vue';

@Component({
    components: {
        Button,
        TextHtml,
    },
})
export default class PcModal extends Vue {
    @Prop({ default: 'エラー' })
    title!: string;

    @Prop({ default: 'error' })
    type!: string;

    @Prop({ default: 'center' })
    footerLayout!: string;

    validator(value: string): boolean {
        let _r = false;
        switch (value) {
            case 'flex-start':
            case 'flex-end':
            case 'center':
            case 'space-between':
            case 'space-around':
                _r = true;
                break;
        }
        return _r;
    }
    footerClass(): string {
        let _r = '';
        if (this.footerLayout !== 'center') {
            _r = `-${this.footerLayout}`;
        }
        return _r;
    }
    hasSlot(name = 'default'): boolean {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
    }
}
