/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from 'aws-amplify';
import { ActionTree } from 'vuex';
import client from '@/api/client';
import { AuthState, User } from './types';
import { RootState } from '../types';

export const actions: ActionTree<AuthState, RootState> = {
    async signOut({ commit }): Promise<void> {
        await Auth.signOut({ global: false }).catch(async () => {
            //TODO:エラーハンドリング
        });
        commit('clearAuthState');
    },
    async fetchUserData({ commit }): Promise<void> {
        const currentUser = await Auth.currentUserInfo();
        try {
            const response = await client.get(`/user/auth/${currentUser.username}`);
            if (response.data) {
                const user: User = response.data;
                commit('userLoaded', user);
            } else {
                commit('clearAuthState');
            }
        } catch (error) {
            commit('clearAuthState');
        }
    },
};
