import { MutationTree } from 'vuex';
import { ExamState, ExamTimer } from './types';
import { getDefaultState } from './state';

export const mutations: MutationTree<ExamState> = {
    setExamTime(state, examTimer: ExamTimer) {
        if (state.examTimers) {
            const index = state.examTimers.findIndex((timer: ExamTimer) => timer.id === examTimer.id);
            if (index !== -1) {
                state.examTimers[index] = examTimer;
            } else {
                state.examTimers.push(examTimer);
            }
        } else {
            state.examTimers = [];
            state.examTimers.push(examTimer);
        }
    },
    setInProgressExamTopicId(state, topicId: string | undefined) {
        state.inProgressExamTopicId = topicId;
    },
    clearExamState(state) {
        Object.assign(state, getDefaultState());
    },
};
