export default class DateUtils {
    private static zeroPadding(n: number | string): string {
        return ('00' + n).slice(-2);
    }
    public static getDateStr(date: Date): string {
        return `${date.getFullYear()}/${this.zeroPadding(date.getMonth() + 1)}/${this.zeroPadding(date.getDate())}`;
    }
    public static getDateStrMonthDay(date: Date): string {
        return `${this.zeroPadding(date.getMonth() + 1)}/${this.zeroPadding(date.getDate())}`;
    }
    public static getDateStrMonthDayHyphen(date: Date): string {
        return `${date.getMonth() + 1}-${this.zeroPadding(date.getDate())}`;
    }
    public static getTimeStr(date: Date): string {
        return `${this.zeroPadding(date.getHours())}:${this.zeroPadding(date.getMinutes())}`;
    }

    public static toLocaleString(dt: string | undefined): string {
        if (!dt) {
            return '';
        }
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'Asia/Tokyo',
            hour12: false,
        };
        const toLocaleDateTime = new Intl.DateTimeFormat('ja-JP', options);

        return toLocaleDateTime.format(new Date(dt));
    }

    public static isNowWithinRange(start: string | null, finish: string | null): boolean {
        const now = new Date();
        if (start && finish) {
            return new Date(start) <= now && now <= new Date(finish);
        } else if (start) {
            return new Date(start) <= now;
        } else if (finish) {
            return now <= new Date(finish);
        } else {
            return true;
        }
    }
}
