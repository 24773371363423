import { MutationTree } from 'vuex';
import { UiState } from './types';
import { getDefaultState } from './state';

export const mutations: MutationTree<UiState> = {
    addOpenLesson(state, id) {
        state.openLessons.push(id);
    },
    removeOpenLesson(state, id) {
        state.openLessons = state.openLessons.filter((_id) => _id !== id);
    },
    setScrollOffset(state, offset) {
        state.scrollOffset = offset;
    },
    clearUiState(state) {
        Object.assign(state, getDefaultState());
    },
};
