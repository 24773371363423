import { GetterTree } from 'vuex';
import { UiState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<UiState, RootState> = {
    isLessonOpen(state): (id: string) => boolean {
        return (id: string) => {
            return state.openLessons.find((_id) => _id === id) != null;
        };
    },
    scrollOffset(state): number {
        return state.scrollOffset;
    },
};
