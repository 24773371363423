
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
    @Prop({ type: Boolean, default: false })
    isPrimary!: boolean;

    @Prop({ type: Boolean, default: false })
    isSmall!: boolean;

    @Prop({ type: Boolean, default: false })
    isLarge!: boolean;

    @Prop({ type: Boolean, default: false })
    isDisabled!: boolean;
}
