import { ActionTree } from 'vuex';
import { UiState } from './types';
import { RootState } from '../types';

export const actions: ActionTree<UiState, RootState> = {
    toggleLessonOpened({ getters, commit }, payload: { id: string }): void {
        if (getters.isLessonOpen(payload.id)) {
            commit('removeOpenLesson', payload.id);
        } else {
            commit('addOpenLesson', payload.id);
        }
    },
};
