import { AxiosResponse } from 'axios';
import client from '@/api/client';

export default class Network {
    public static async fetchImage(url: string): Promise<Blob | null> {
        try {
            const response = await client.get(url, {
                responseType: 'arraybuffer',
            });
            return new Blob([response.data], { type: response.headers['content-type'] });
        } catch (e) {
            // TODO: エラーハンドリング
        }

        return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static async fetchData(url: string): Promise<AxiosResponse<any> | null> {
        try {
            return await client.get(url, {
                responseType: 'arraybuffer',
            });
            // return new Blob([response.data]);
        } catch (e) {
            // TODO: エラーハンドリング
        }

        return null;
    }

    public static async downloadImageLinks(url: string | undefined): Promise<string | undefined> {
        if (!url) {
            return '';
        }

        const URL = window.URL || window.webkitURL;

        if (
            url.startsWith(process.env.VUE_APP_API_URL_CONTENTS_PROXY as string) ||
            url.startsWith(process.env.VUE_APP_API_URL_CONTENTS_PROXY_V2 as string)
        ) {
            const blob = await Network.fetchImage(url);
            if (blob) {
                const data = URL.createObjectURL(blob);

                //For Firefox it is necessary to delay revoking the ObjectURL
                setTimeout(() => {
                    const URL = window.URL || window.webkitURL;
                    URL.revokeObjectURL(data);
                }, 100);

                return data;
            } else {
                return '';
            }
        } else {
            return url;
        }
    }
}
