import axios, { AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import * as router from '../router';
import { outputLog } from '@/api/log';
import { Auth } from 'aws-amplify';

export const networkErrorMessage = '通信エラーが発生しました。通信環境をご確認のうえ、再度実行してください';

const client = axios.create({
    baseURL: process.env.VUE_APP_API_URL_USER_BACKEND,
    headers: {
        'Content-Type': 'application/json',
    },
    responseType: 'json',
});
client.interceptors.request.use(async (config) => {
    let session;
    try {
        session = await Auth.currentSession();
    } catch {
        router.default.push('/auth');
        return;
    }
    config.headers.Authorization = session.getIdToken().getJwtToken();
    return config;
});

client.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error) => {
        if (axios.isAxiosError(error)) {
            try {
                const config = error.config;
                const logBody: Record<string, unknown> = {
                    message: 'axios error.',
                    method: config.method,
                    requestUrl: `${config.baseURL}${config.url}`,
                    location: window.location.href,
                };
                if (config.data) {
                    logBody.requestData = config.data;
                }
                if (error.response) {
                    logBody.responseStatus = error.response.status;
                    logBody.responseData = error.response.data;
                }
                outputLog(logBody);
                // eslint-disable-next-line no-empty
            } catch (_) {}

            const status = error.response?.status;
            if (status === 401) {
                router.default.push('/auth');
            } else if (status === 404) {
                router.default.push('/not-found');
            }
        }
        return Promise.reject(error);
    }
);
axiosRetry(client, { retries: 3 });

export default client;
