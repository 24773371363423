import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Buefy from 'buefy';
import Amplify, { Auth } from 'aws-amplify';
import VueObserveVisibility from 'vue-observe-visibility';
import 'intersection-observer';
import VueGtag from 'vue-gtag';

import '@/scss/global.scss';

import '@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
import '@fortawesome/fontawesome-free-webfonts/css/fa-brands.css';
import '@fortawesome/fontawesome-free-webfonts/css/fa-regular.css';
import '@fortawesome/fontawesome-free-webfonts/css/fa-solid.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle, faChevronLeft, faList, faTimes, faQuestion, faLock, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.use(VueObserveVisibility);
if (process.env.VUE_APP_ANALYTICS_ID) {
    Vue.use(
        VueGtag,
        {
            config: {
                id: process.env.VUE_APP_ANALYTICS_ID,
                params: { send_page_view: false },
            },
        },
        router
    );
}

library.add(faExclamationTriangle);
library.add(faChevronLeft);
library.add(faList);
library.add(faTimes);
library.add(faQuestion);
library.add(faLock);
library.add(faFile);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Amplify.configure({
    Auth: {
        identityPoolId: process.env.VUE_APP_AWS_AUTH_IDENTITY_ID,
        region: process.env.VUE_APP_AWS_AUTH_REGION,
        userPoolId: process.env.VUE_APP_AWS_AUTH_USER_POOL_ID,
        userPoolWebClientId: process.env.VUE_APP_AWS_AUTH_USER_POOL_WEB_CLIENT_ID,
        storage: window.sessionStorage,
    },
    API: {
        endpoints: [
            {
                name: process.env.VUE_APP_API_URL_USER_BACKEND_NAME,
                endpoint: process.env.VUE_APP_API_URL_USER_BACKEND,
                region: process.env.VUE_APP_AWS_API_REGION,
                custom_header: async () => {
                    // Alternatively, with Cognito User Pools use this:
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
                },
            },
        ],
    },
    Storage: {
        AWSS3: {
            identityPoolId: process.env.VUE_APP_AWS_AUTH_IDENTITY_ID,
            bucket: process.env.VUE_APP_AWS_STORAGE,
            region: process.env.VUE_APP_AWS_STORAGE_REGION,
        },
    },
});

Vue.use(Buefy, {
    defaultIconPack: 'fas',
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
