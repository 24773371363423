/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationTree } from 'vuex';
import { AuthState } from './types';
import { getDefaultState } from './state';

export const mutations: MutationTree<AuthState> = {
    userLoaded(state, payload: any) {
        state.user = payload;
    },
    clearAuthState(state) {
        Object.assign(state, getDefaultState());
    },
};
