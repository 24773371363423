import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store/index';
import MobileDetect from '@/utils/MobileDetect';
import { Course } from '@/api/userAppApi/types';
import { fetchCourses } from '@/api/userAppApi/course';
import { Auth } from 'aws-amplify';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/auth',
        name: 'Login',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/Login.vue') : import('../views/pc/Login.vue');
        },
    },
    {
        path: '/agreement',
        name: 'UserAgreement',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/UserAgreement.vue') : import('../views/pc/UserAgreement.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/',
        redirect: '/cs',
    },
    {
        path: '/li',
        name: 'Courses',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/Home.vue') : import('../views/pc/CourseList.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/li/:id',
        name: 'CourseInfo',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/CourseInfo.vue') : import('../views/pc/CourseInfo.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/li/:courseId/:moduleId',
        name: 'ModuleInfo',
        component: () => {
            //return MobileDetect.isMobile() ? import('../views/sp/ModuleInfo.vue') : import('../views/pc/ModuleInfo.vue');
            return import('../views/sp/ModuleInfo.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/li/:courseId/:moduleId/:lessonId/:topicId',
        name: 'TopicInfo',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/TopicInfo.vue') : import('../views/pc/TopicInfo.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/ex/question/:courseId/:moduleId/:lessonId/:topicId/:examId/:questionId',
        name: 'QuestionInfo',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/QuestionInfo.vue') : import('../views/pc/QuestionInfo.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/ex/result/detail/:courseId/:moduleId/:lessonId/:topicId/:examId/:resultNo/:questionId',
        name: 'ExamQuestionResultDetail',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/ExamQuestionResultDetail.vue') : import('../views/pc/ExamQuestionResultDetail.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/ex/result/:courseId/:moduleId/:lessonId/:topicId/:examId/:resultNo',
        name: 'ExamQuestionResult',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/ExamQuestionResult.vue') : import('../views/pc/ExamQuestionResult.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/ex/history/:courseId/:moduleId/:lessonId/:topicId/:examId/:resultNo',
        name: 'ExamQuestionHistory',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/ExamQuestionHistory.vue') : import('../views/pc/ExamQuestionHistory.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/ex/:courseId/:moduleId/:lessonId/:topicId/:examId',
        name: 'ExamInfo',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/ExamInfo.vue') : import('../views/pc/ExamInfo.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/dr/:courseId/:moduleId/:lessonId/:topicId/:examId',
        name: 'DrillInfo',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/DrillInfo.vue') : import('../views/pc/DrillInfo.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/dr/question/commentary/:courseId/:moduleId/:lessonId/:topicId/:examId/:resultNo/:questionId',
        name: 'DrillQuestionCommentary',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/DrillCommentaryInfo.vue') : import('../views/pc/DrillCommentaryInfo.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/dr/question/:courseId/:moduleId/:lessonId/:topicId/:examId/:questionId',
        name: 'DrillQuestionInfo',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/DrillQuestionInfo.vue') : import('../views/pc/DrillQuestionInfo.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/dr/result/detail/:courseId/:moduleId/:lessonId/:topicId/:examId/:resultNo/:questionId',
        name: 'DrillQuestionResultDetail',
        component: () => {
            return MobileDetect.isMobile()
                ? import('../views/sp/DrillQuestionResultDetail.vue')
                : import('../views/pc/DrillQuestionResultDetail.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/dr/result/:courseId/:moduleId/:lessonId/:topicId/:examId/:resultNo',
        name: 'DrillQuestionResult',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/DrillQuestionResult.vue') : import('../views/pc/DrillQuestionResult.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/dr/history/:courseId/:moduleId/:lessonId/:topicId/:examId/:resultNo',
        name: 'DrillQuestionHistory',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/DrillQuestionHistory.vue') : import('../views/pc/DrillQuestionHistory.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/cs',
        redirect: '/cs/0',
    },
    {
        path: '/cs/:tabIndex',
        name: 'Articles',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/Home.vue') : import('../views/pc/ArticleList.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/cs/:tabIndex/:id',
        name: 'ArticleInfo',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/ArticleInfo.vue') : import('../views/pc/ArticleInfo.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/mypage',
        name: 'MyPage',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/MyPage.vue') : import('../views/pc/MyPage.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/mypage-registration-info',
        name: 'MyPageRegistrationInfo',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/MyPageRegistrationInfo.vue') : import('../views/pc/MyPageRegistrationInfo.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/mypage-change-password',
        name: 'MyPageChangePassword',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/MyPageChangePassword.vue') : import('../views/pc/MyPageChangePassword.vue');
        },
        meta: { requiresAuth: true },
    },
    {
        path: '/pukupuku',
        name: 'PukuPuku',
        component: () => {
            window.open('https://dev.dk8h5tyq6d07z.amplifyapp.com/chat/', 'pukupuku');
            return;
        },
    },
    {
        path: '*',
        name: 'NotFound',
        component: () => {
            return MobileDetect.isMobile() ? import('../views/sp/NotFound.vue') : import('../views/pc/NotFound.vue');
        },
        meta: { requiresAuth: true },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeResolve(async (to, from, next) => {
    if (to.fullPath === '/auth') {
        try {
            await Auth.currentSession();
            return next({ path: '/' });
            // eslint-disable-next-line no-empty
        } catch {}
    }
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        try {
            await Auth.currentSession();
        } catch {
            await store.dispatch('auth/signOut');
            return next({ path: '/auth' });
        }
        if (from.name !== 'Login') {
            const user = await store.getters['auth/user'];
            if (!user?.agreement) {
                await store.dispatch('auth/signOut');
                return next({ path: '/auth' });
            }
        }
    }

    if (to.name && routesValidatedWithCourseId.includes(to.name)) {
        const courseId = to.params.courseId;
        const allowedCourses: Course[] = (await fetchCourses(true)).all;
        if (!allowedCourses.some(({ id }) => id.split(':')[0] === courseId?.split(':')[0])) {
            return next({ name: 'NotFound' });
        }
    }
    if (to.name === 'QuestionInfo' && from.name !== 'QuestionInfo') {
        if (store.getters['exam/inProgressExamTopicId'] !== to.params.topicId) {
            const params = Object.assign({}, to.params);
            delete params['questionId'];
            return next({ name: 'ExamInfo', params });
        }
    }
    return next();
});

export default router;

const routesValidatedWithCourseId = ['ExamInfo', 'QuestionInfo', 'DrillInfo', 'DrillQuestionInfo', 'ExamQuestionHistory', 'DrillQuestionHistory'];
