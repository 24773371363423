
import { Component, Vue } from 'vue-property-decorator';
import PcModal from '@/components/molecules/PcModal.vue';
import Button from '@/components/atoms/Button.vue';

@Component({
    components: {
        PcModal,
        Button,
    },
})
export default class ForceReloadModal extends Vue {
    normalizedUrl = process.env.VUE_APP_INDEX_PAGE_URL || '';
    checkIntervalMinutes = parseInt(process.env.VUE_APP_CHECK_INTERVAL_MINUTES || '') || 0;
    previousHash: string | undefined = undefined;
    isChanged = false;
    reloadCheckTimer: ReturnType<typeof setInterval> | undefined = undefined;

    created(): void {
        this.fetchServer();
        this.reloadCheckTimer = setInterval(this.fetchServer, this.checkIntervalMinutes * 60 * 1000);
    }
    beforeDestroy(): void {
        if (this.reloadCheckTimer) {
            window.clearInterval(this.reloadCheckTimer);
        }
    }
    fetchServer(): void {
        fetch(this.normalizedUrl)
            .then((res) => {
                if (res.status !== 200) {
                    throw Error('Cannot access server.');
                }
                return res.text();
            })
            .then((html) => {
                const hash = this.createHash(html);
                this.judgeHash(hash);
            })
            .catch(() => {
                // TODO: エラーハンドリング
            });
    }
    createHash(str: string): string {
        const len = str.length;
        let hash = 0;
        if (len === 0) return hash.toString();
        let i;
        for (i = 0; i < len; i++) {
            hash = (hash << 5) - hash + str.charCodeAt(i);
            hash |= 0; // Convert to 32bit integer
        }
        return hash.toString();
    }
    judgeHash(hash: string): void {
        if (!this.previousHash) {
            this.previousHash = hash;
            return;
        }
        if (this.previousHash !== hash) {
            this.isChanged = true;
        }
    }
    reload(): void {
        location.reload();
    }
    cancel(): void {
        this.isChanged = false;
    }
}
