import { ExamState } from './types';

export const getDefaultState: () => ExamState = () => {
    return {
        examTimers: [],
        inProgressExamTopicId: undefined,
    };
};

export const state = getDefaultState();
